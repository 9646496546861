import React from 'react'
import { Form, Button, Dropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"

const ButtonField = ({type, name, value, fieldClass, step,handlechange,handlechange2, isNewsletter}) => (
	<>
		<Button variant="" type={type}  className={`${fieldClass ? fieldClass : "btn btn-fill-primary w-100"}`}>
			{name}
			<i className="icon-right"></i>
		</Button>
		<div className="contact-terms">By clicking {isNewsletter ? "Subscribe" : "Submit Enquiry"}, you agree to our <Link to="/terms-and-conditions/">Terms</Link> and <Link to="/privacy-policy/">Privacy Policy.</Link></div>

	</>
);

export default ButtonField;